import * as React from 'react';
import { ProductPage } from '../components/ProductPage';

const productDescription = `
<h2>Szkolenie (<i>430,5 zł/h</i>)</h2>
`;

export default () => {
  return (
    <ProductPage
      productId="1"
      initialPrice={430.5}
      productDescription={productDescription}
    />
  );
};
